<script>
import Axios from "axios";
import TowDriverViewDetails from "./tow-driver-view/details";
import TowDriverViewContacts from "./tow-driver-view/contacts";
import TowDriverViewLocations from "./tow-driver-view/locations";

export default {
  name: 'TowDriverView',
  components: {
    TowDriverViewDetails,
    TowDriverViewContacts,
    TowDriverViewLocations,
  },
  data(){
    return {
      towDriver: {},
      currentTab: null,
    };
  },
  computed: {
    towDriverName: {
      get(){
        if(this.towDriver.companyName == 'New Tow Driver Company'){
          return '';
        }
        return this.towDriver.companyName;
      },
      set(val){
        if(val === ''){
          this.towDriver.companyName = 'New Tow Driver Company';
        } else {
          this.towDriver.companyName = val;
        }
      }
    },
  },
  mounted() {
    this.loadTowDriver();
  },
  methods: {
    loadTowDriver(callback = null){
      NProgress.start()
      Axios.get(`/ir/tow-driver/${this.$route.params.towDriverId}`)
          .then(res => {
            if(res.data && res.data._status){
              res.data.data.locations.forEach(l => { l.edit = false; });
              res.data.data.contacts .forEach(c => { c.edit = false; });
              this.towDriver = res.data.data;
              if(callback) callback();
            }
          })
          .catch(e => console.error(e))
          .finally(() => NProgress.done());
    },
    saveTowDriver(){
      NProgress.start()
      Axios.post(`/ir/tow-driver/${this.$route.params.towDriverId}`, this.towDriver)
          .then(res => {
            if(res.data && res.data._status){
              this.$toast.success("Tow driver data updated successfully");
            }
          })
          .catch(e => {
            console.error(e)
            this.$toast.error("Cannot update tow driver data");
          })
          .finally(() => NProgress.done());
    },
    onTabChange(e){
      this.currentTab = e.tab.name;
    },
    addContact(){
      Axios.post(`/ir/tow-driver/${this.towDriver.id}/add-contact`)
          .then(response => {
            if(response.data && response.data._status){
              this.$toast.success("New Contact Added");
              this.loadTowDriver(() => {
                this.towDriver.contacts?.forEach(c => {
                  if(c.id == response.data.contactId){
                    c.edit = true;
                  }
                })
              });
            }
          })
          .catch();
    },
    addLocation(){
      Axios.post(`/ir/tow-driver/${this.towDriver.id}/add-location`)
          .then(response => {
            if(response.data && response.data._status){
              this.$toast.success("New Location Added");
              this.loadTowDriver(() => {
                this.towDriver.locations?.forEach(c => {
                  if(c.id == response.data.locationId){
                    c.edit = true;
                  }
                })
              });
            }
          })
          .catch();
    },
  },
}
</script>

<template>
  <div class="settings-page">
    <div class="page-header">
      <h4>{{ towDriverName }}</h4>
      <ol class="breadcrumbs">
        <li class="breadcrumb-item home">
          <router-link to="/">Home</router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link to="/tow-drivers">Tow Drivers</router-link>
        </li>
        <li class="breadcrumb-item active">
          {{ towDriverName ? towDriverName : 'Add a New Tow Driving Company' }}
        </li>
      </ol>
      <div class="page-header-nav-btn">
        <a @click="saveTowDriver" class="btn btn-primary btn-submit-quote">
          <span>Save</span>
        </a>
      </div>

    </div>
    <div class="tow-driver-body">
      <tabs ref="tow-driver-table" cache-lifetime="0" @changed="onTabChange">
        <tab name="Details" id="tow-driver-details" ref="towDriverViewDetailsTab" :class-name="'tab-content-table'">
          <tow-driver-view-details :towDriver="towDriver"></tow-driver-view-details>
        </tab>
        <tab name="Contacts" id="tow-driver-contacts" :class-name="'tab-content-table'">
          <tow-driver-view-contacts :contacts="towDriver.contacts" :locations="towDriver.locations" @contact-updated="loadTowDriver"></tow-driver-view-contacts>
        </tab>
        <tab name="Locations" id="tow-driver-locations" :class-name="'tab-content-table'">
          <tow-driver-view-locations :locations="towDriver.locations" @location-updated="loadTowDriver"></tow-driver-view-locations>
        </tab>
        <template slot="nav-item-right">
          <div class="tabs-nav-right-btn ml-4" v-show="currentTab == 'Contacts'">
            <a @click="addContact" class="btn btn-primary btn-submit-quote">
              <i class='bx bx-plus'></i><span>Add Contact</span>
            </a>
          </div>
          <div class="tabs-nav-right-btn ml-4" v-show="currentTab == 'Locations'">
            <a @click="addLocation" class="btn btn-primary btn-submit-quote">
              <i class='bx bx-plus'></i><span>Add Location</span>
            </a>
          </div>
          <div class="tabs-nav-right-btn ml-4" v-show="currentTab == 'Details'">
            <a @click="saveTowDriver" class="btn btn-primary btn-submit-quote">
              <span>Save</span>
            </a>
          </div>
        </template>
      </tabs>
    </div>
  </div>
</template>
