<template>
  <div class="claimlinq-page">
    <div class="page-header">
      <h4>Assessors</h4>
      <ol class="breadcrumbs">
        <li class="breadcrumb-item">
          <router-link :to="'/dashboard'">Home</router-link>
        </li>
        <li class="breadcrumb-item active">View Assessors</li>
      </ol>
      <div class="page-header-nav-btn">
      </div>
    </div>
    <div class="claimlinq-body">
      <tabs ref="claimlinq-table" cache-lifetime="0" :options="{useUrlFragment:true}">
        <tab name="All" id="assessors-all" :class-name="'tab-content-table'">
          <div class="use-pagination-block-bottom">
            <b-table
                responsive
                hover
                :items="this.assessors"
                :current-page="currentPage"
                :per-page="perPage"
                @filtered="onFiltered"
                :fields="allFields"
                :filter="filter"
                @row-clicked="showAssessor"
            >
              <template v-slot:head()="data">
                <div v-if="!data.field.sortable">{{data.label}}</div>
                <div v-if="data.field.sortable" class="table-sorting">{{data.label}}<i></i></div>
              </template>
              <template v-slot:cell(active)="data">
                {{ data.item.active ? 'Yes' : 'No' }}
              </template>
              <template v-slot:cell(createdOn)="data">
                {{ data.item.createdOn | formatDatetime }}
              </template>
            </b-table>
          </div>
          <block-pagination
              :role="'Assessors'"
              :arrayOfSomeone="assessors"
              :arrayOfSomeoneFiltered="computedAssessorsFiltered"
              :currentPage="currentPage"
              @changedCurrentPage="value => currentPage = value"
              :perPage="perPage"
              @changedPerPage="value => perPage = value"
          >
          </block-pagination>
        </tab>
        <template slot="nav-item-right">
          <div class="tabs-nav-right-search">
            <b-form-input v-model="filter" type="text" class="search-input" placeholder="Search or Filter results" debounce="100"></b-form-input>
          </div>
        </template>
      </tabs>
    </div>
  </div>
</template>

<script>
import Axios from "axios";
import {mapActions, mapGetters} from "vuex";
import BlockPagination from '../../components/utility/block-pagination';

export default {
  name: 'Assessors',
  components: {
    BlockPagination,
  },
  data(){
    return {
      allFields: [
        {label: "Assessor Name", key: "fullName", sortable: true, tdClass: 'clickable number', thClass: ''},
        {label: "Company Name", key: "companyName", sortable: true, tdClass: 'clickable ', thClass: ''},
        {label: "Email", key: "email", sortable: true, tdClass: 'clickable number', thClass: ''},
        {label: "Contact Nbr", key: "phoneNumber", sortable: true, tdClass: 'clickable ', thClass: ''},
        {label: "Active", key: "active", sortable: true, tdClass: 'clickable ', thClass: ''},
        {label: "Date Created", key: "createdOn", sortable: true, tdClass: 'clickable', thClass: ''},
      ],
      assessorsFiltered: [],
      filter: null,
      assessors: [],
      perPage: 50,
      currentPage: 1,
    };
  },
  mounted() {
    if (!this.filter) this.filter = this.gettersFilter;
    if (this.gettersFilter) this.filter = this.gettersFilter;
    this.loadAssessors();
  },
  computed: {
    ...mapGetters({
      gettersFilter: 'assessor/getFilter',
    }),
    computedAssessorsFiltered() {
      return this.assessorsFiltered;
    }
  },
  methods: {
    ...mapActions({
      setFilter: 'assessor/setFilter',
    }),
    onFiltered(fi) {
      this.assessorsFiltered = fi;
    },
    loadAssessors(){
      NProgress.start();
      Axios.get('/ir/assessors')
        .then(response => {
          if(response && response.data){
            this.assessors = response.data;
            this.assessorsFiltered = response.data;
          }
        })
        .catch(e => console.error(e))
        .finally(() => { NProgress.done() })
    },
    showAssessor(item){
      this.$router.push("/assessor/" + item.id);
    },
  },
  watch: {
    filter(val) {
      this.setFilter(val);
    },
  },
};
</script>
