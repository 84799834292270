<script>

import Axios from "axios";

export default {
  components: {
  },
  name: 'TowDriverViewLocations',
  props: {
    locations: {
      type: Array,
      default: () => ([
        {
          location: '',
          levelUnitLot: '',
          streetName: '',
          suburb: '',
          state: '',
          postcode: '',
          country: 'AU',
        }
      ])
    },
  },
  data(){
    return {
      allFields: [
        {label: "Location Name", key: "name", sortable: true, tdClass: 'number', thClass: ''},
        {label: "Address", key: "address", sortable: true, tdClass: '', thClass: ''},
        {label: "Phone", key: "phone", sortable: true, tdClass: 'number', thClass: ''},
        {label: "Email", key: "email", sortable: true, tdClass: '', thClass: ''},
        {label: "Operating Hours", key: "operatingHours", sortable: true, tdClass: '', thClass: ''},
        {label: "Action", key: "action", sortable: true, tdClass: '', thClass: ''},
      ],
      filter: '',
    };
  },
  methods: {
    clickContactRow(){},
    saveLocation(item){
      NProgress.start();
      Axios.post(`/ir/tow-driver/${this.$route.params.towDriverId}`, {
        id: this.$route.params.towDriverId,
        locations: [item]
      })
        .then(response => {
          if(response.data && response.data._status){
            this.$toast.success('Location has been updated!');
            this.$emit('location-updated');
          }
        })
        .catch(e => console.error(e))
        .finally(() => {
          NProgress.done();
        })
      item.edit = false;
    },
    removeLocation(item){
      if(confirm(`Are you sure to remove ${item.name}?`)){
        NProgress.start();
        Axios.post(`/ir/tow-driver/${this.$route.params.towDriverId}/remove-location`, {
          locationId: item.id
        })
            .then(response => {
              if(response.data && response.data._status){
                this.$toast.success('Location has been removed!');
                this.$emit('location-updated');
              }
            })
            .catch(e => console.error(e))
            .finally(() => {
              NProgress.done();
            })
      }
    },
  },
}
</script>

<template>

  <div class="row">
    <div class="col-md-12">
      <div class="card">

        <b-table
            responsive
            hover
            :items="locations"
            :fields="allFields"
            :filter="filter"
            @row-clicked="clickContactRow"
            ref="locationsTable"
        >
          <template v-slot:head()="data">
            <div v-if="!data.field.sortable">{{data.label}}</div>
            <div v-if="data.field.sortable" class="table-sorting">{{data.label}}<i></i></div>
          </template>

          <template v-slot:cell(name)="data">
            <span v-show="!data.item.edit">{{ data.item.name }}</span>
            <input v-show="data.item.edit" type="text" class="form-control" v-model="data.item.name">
          </template>

          <template v-slot:cell(address)="data">
            <span v-show="!data.item.edit">{{ data.item.address }}</span>
            <input v-show="data.item.edit" type="text" class="form-control" v-model="data.item.address">
          </template>

          <template v-slot:cell(phone)="data">
            <span v-show="!data.item.edit">{{ data.item.phone }}</span>
            <input v-show="data.item.edit" type="tel" class="form-control" v-model="data.item.phone">
          </template>

          <template v-slot:cell(email)="data">
            <span v-show="!data.item.edit">{{ data.item.email }}</span>
            <input v-show="data.item.edit" type="email" class="form-control" v-model="data.item.email">
          </template>

          <template v-slot:cell(operatingHours)="data">
            <span v-show="!data.item.edit">{{ data.item.operatingHours }}</span>
            <input v-show="data.item.edit" type="text" class="form-control" v-model="data.item.operatingHours">
          </template>

          <template v-slot:cell(action)="data">
            <a href="#" class="mr-0-5" @click.prevent="data.item.edit = true" v-show="!data.item.edit">
              <i class='bx bx-pencil font-20 text-black'></i>
            </a>
            <a href="#" @click.prevent="removeLocation(data.item)" v-show="!data.item.edit">
              <i class='bx bx-trash font-20 text-danger'></i>
            </a>
            <a href="#" class="mr-0-5" @click.prevent="saveLocation(data.item)" v-show="data.item.edit">
              <i class='fa fa-save font-18 text-success' style="vertical-align: text-bottom;"></i>
            </a>
            <a href="#" v-show="data.item.edit" @click.prevent="data.item.edit = false">
              <i class='bx bx-x-circle font-20 text-danger'></i>
            </a>
          </template>

        </b-table>

      </div>
    </div>
  </div>


</template>
