<script>
import BlockAddress from '@/components/block-address';
import {mapGetters} from "vuex";
import multiselect from 'vue-multiselect';

export default {
  components: {
    multiselect,
    BlockAddress,
  },
  name: 'TowDriverViewDetails',
  props: {
    towDriver: {
      type: Object,
      default: () => ({
        location: '',
        levelUnitLot: '',
        streetName: '',
        suburb: '',
        state: '',
        postcode: '',
        country: 'AU',
      })
    },
  },
  computed: {
    ...mapGetters({
      addressStates: 'addressStates',
      addressCountries: 'addressCountries',
    }),
    postalState: {
      get(){
        let o = null;
        this.addressStates.forEach(s => {
          if(this.towDriver.addressState == s.key){
            o = s;
          }
        })
        return o;
      },
      set(val){
        this.towDriver.addressState = val.key;
      }
    },
    postalCountry: {
      get(){
        let o = null;
        this.addressCountries.forEach(s => {
          if(this.towDriver.addressCountry == s.key){
            o = s;
          }
        })
        return o;
      },
      set(val){
        this.towDriver.addressCountry = val.key;
      }
    },
    towDriverName: {
      get(){
        if(this.towDriver.companyName == 'New Tow Driver Company'){
          return '';
        }
        return this.towDriver.companyName;
      },
      set(val){
        if(val === ''){
          this.towDriver.companyName = 'New Tow Driver Company';
        } else {
          this.towDriver.companyName = val;
        }
      }
    },
  },
  mounted() {
    console.log(this.addressStates)
  }
}
</script>

<template>
  <div class="row">
    <div class="col-md-8">
      <div class="card">
        <div class="card-header bg-navyblue">
          <strong>Details</strong>
        </div>
        <div class="card-block bg-white">

          <div class="row">
            <div class="col-md-6">

              <div class="form-group row">
                <label for="owner" class="col-sm-4 col-4 col-form-label">Company Name</label>
                <div class="col-sm-8 col-8">
                  <input type="text" class="form-control" v-model="towDriverName">
                </div>
              </div>

              <div class="form-group row">
                <label for="owner" class="col-sm-4 col-4 col-form-label">Contact Nbr</label>
                <div class="col-sm-8 col-8">
                  <input type="text" class="form-control" v-model="towDriver.phone">
                </div>
              </div>

              <div class="form-group row">
                <label for="owner" class="col-sm-4 col-4 col-form-label">Email</label>
                <div class="col-sm-8 col-8">
                  <input type="text" class="form-control" v-model="towDriver.email">
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-4 col-4 col-form-label">Search Address</label>
                <div class="col-sm-8 col-8">
                  <input type="text" v-model="towDriver.addressSearch" class="form-control">
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-4 col-form-label">Lot / Unit / Lvl</label>
                <div class="col-sm-8 col-8">
                  <input type="text" v-model="towDriver.addressLotUnitLvl" class="form-control">
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-4 col-form-label">Street Name / Nbr</label>
                <div class="col-sm-8 col-8">
                  <input type="text" v-model="towDriver.addressStreet" class="form-control">
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-4 col-form-label">Suburb</label>
                <div class="col-sm-8 col-8">
                  <input type="text" v-model="towDriver.addressSuburb" class="form-control">
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-4 col-form-label">State</label>
                <div class="col-sm-8 col-8">
                  <multiselect
                      v-model="postalState"
                      :options="addressStates"
                      track-by="key"
                      label="value"

                      :showLabels="false"
                      :option-height="29"
                      :max-height="203"
                      :close-on-select="true"
                      :allow-empty="false"
                      placeholder="Select state"
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-4 col-form-label">Postcode</label>
                <div class="col-sm-8 col-8">
                  <input type="text" v-model="towDriver.addressPostcode" class="form-control">
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-4 col-form-label">Country</label>
                <div class="col-sm-8 col-8">
                  <multiselect
                      v-model="postalCountry"
                      :options="addressCountries"
                      track-by="key"
                      label="value"
                      :showLabels="false"
                      :option-height="29"
                      :max-height="203"
                      :close-on-select="true"
                      :allow-empty="false"
                      placeholder="Select country"
                  />
                </div>
              </div>

            </div>

            <div class="col-md-6">

              <div class="form-group row">
                <label for="owner" class="col-sm-4 col-4 col-form-label">License Number</label>
                <div class="col-sm-8 col-8">
                  <input type="text" class="form-control" v-model="towDriver.licenseNumber">
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-4 col-4 col-form-label">Tow Truck Types</label>
                <div class="col-sm-8 col-8">

                  <label class="form-check checkbox">
                    <input v-model="towDriver.truckTypeFlatbed"
                           class="form-check-input" type="checkbox"
                           value="internal">
                    <span class="icon"><i class='bx bx-check'></i></span>
                    <span class="text">Flatbed</span>

                  </label>

                  <label class="form-check checkbox">
                    <input v-model="towDriver.truckTypeHook"
                           class="form-check-input" type="checkbox"
                           value="subcontractor">
                    <span class="icon"><i class='bx bx-check'></i></span>
                    <span class="text">Hook & Chain</span>
                  </label>

                  <label class="form-check checkbox">
                    <input v-model="towDriver.truckTypeWheelLift"
                           class="form-check-input" type="checkbox"
                           value="external">
                    <span class="icon"><i class='bx bx-check'></i></span>
                    <span class="text">Wheel Lift</span>
                  </label>

                  <label class="form-check checkbox">
                    <input v-model="towDriver.truckTypeIntegrated"
                           class="form-check-input" type="checkbox"
                           value="external">
                    <span class="icon"><i class='bx bx-check'></i></span>
                    <span class="text">Integrated</span>
                  </label>

                </div>
              </div>

            </div>

          </div>

        </div>
      </div>
    </div>

    <div class="col-md-4">
      <div class="card">
        <div class="card-header bg-navyblue">
          <strong>Active</strong>
        </div>
        <div class="card-block bg-white">

          <div class="form-group row">
            <label class="col-sm-4 col-4 col-form-label">Active</label>
            <div class="col-sm-8 col-8">
              <label class="form-check-inline radio">
                <input v-model="towDriver.active"
                       class="form-check-input" type="radio"
                       :value="true">
                <span class="icon"><i class='bx bx-check'></i></span>
                <span class="text">Yes</span>

              </label>
              <label class="form-check-inline radio">
                <input v-model="towDriver.active"
                       class="form-check-input" type="radio"
                       :value="false">
                <span class="icon"><i class='bx bx-check'></i></span>
                <span class="text">No</span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<style scoped>

.col-form-label {
  padding: 8px 0;
}

</style>
