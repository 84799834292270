<template>
  <div class="pagination-block">
    <div class="pagination-block-showing form-inline" style="z-index: 99">
      <span>Showing {{ pageAll.from }} to {{pageAll.to }} of {{ pageAll.total_filtered }} {{role}}</span>
      <span v-if="filteredRowsCount < rowsCount" style="margin-left: 5px"> &#40;filtered from {{pageAll.total_unfiltered }} total {{role}}&#41;</span>
      <span style="margin:auto 5px;"> || </span>
      <span>Show Entries </span>
      <input type="text" class="form-control" v-model="perPage2" @input="updatePerPage(perPage2)" format="0"/>
    </div>
    <div class="pagination-block-paginator">
      <b-pagination align="right"
                    v-if="filteredRowsCount"
                    :total-rows="filteredRowsCount"
                    v-model="currentPage2"
                    @input="updateCurrentPage(currentPage2)"
                    :per-page="computedPerPage"></b-pagination>
    </div>
  </div>

</template>

<script>
import _ from 'lodash';

export default {
  name: "block-pagination",
  data() {
    return {
      currentPage2: this.currentPage,
      perPage2: this.perPage,
    }
  },
  props: {
    role: {
      type: String,
      default: '',
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    perPage: {
      type: Number,
      default: 0,
    },
    perPageForMultipleTables: {
      type: Number,
      default: 0,
    },
    arrayOfSomeoneFiltered: {
      type: Array,
      default() {
        return []
      },
    },
    arrayOfSomeone: {
      type: Array,
      default() {
        return []
      },
    }
  },
  methods: {
    updatePerPage(value) {
      this.$emit('changedPerPage', Number(value))
    },
    updateCurrentPage(value) {
      this.$emit('changedCurrentPage', Number(value))
    },
    updateCustomersPerPage(perPage) {
      if (perPage < 1 || perPage > 1000) {
        this.perPage2 = 50;
      } else {
        this.perPage2 = perPage;
      }
    },
  },
  computed: {
    computedPerPage: {
      get() {
        return this.perPage2;
      },
      set(v) {
        this.debouncedUpdateCustomersPerPage(v);
      }
    },
    rowsCount: function () {
      return this.arrayOfSomeone.length
    },
    filteredRowsCount: function () {
      return this.arrayOfSomeoneFiltered.length
    },
    pageAll: function () {
      let status = [];
      if (this.filteredRowsCount > 0) {
        status["from"] = (this.currentPage2 - 1) * this.computedPerPage + 1;
        status["to"] = this.currentPage2 * this.computedPerPage;
      } else {
        status["from"] = 0;
        status["to"] = 0;
      }

      if (this.filteredRowsCount % this.computedPerPage > 0 && this.currentPage2  > this.filteredRowsCount / this.computedPerPage) {
        status["to"] = this.filteredRowsCount;
      }

      status["total_filtered"] = this.filteredRowsCount;
      status["total_unfiltered"] = this.rowsCount;

      return status;

    },
  },
  watch: {
    arrayOfSomeoneFiltered: function() {
      // if (this.arrayOfSomeoneFiltered.length < this.arrayOfSomeone.length) {
      //   this.updateCurrentPage(1)
      //   this.currentPage2 = 1;
      // }
    },
    perPageForMultipleTables: function(value) {
      this.perPage2 = value;
    }
  },
  created() {
    this.debouncedUpdateCustomersPerPage = _.debounce(function (v) {
      this.updateCustomersPerPage(v);
    }, 700);
  }
}
</script>

<style scoped>

</style>
